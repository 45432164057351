import React from 'react';
import { Stack, TextField, CommandBarButton, IIconProps, Text, Checkbox } from '@fluentui/react';
import {
  AgentAppFunctionAuth,
  AuthenticationType,
} from '../../../../../../Models/API/IAgentAppFunction';
import styles from './FunctionsFormAuthProfile.module.scss';

const addIcon: IIconProps = { iconName: 'CircleAddition' };

export interface IFunctionFormHeadersProps {
  authProfile: AgentAppFunctionAuth | undefined;
  setAuthProfile(authProfile: AgentAppFunctionAuth | undefined): void;
}

const FunctionFormHeaders: React.FC<IFunctionFormHeadersProps> = ({
  authProfile,
  setAuthProfile,
}) => {
  const updateHeaderParameters = (
    index: number,
    field: keyof { Key: string; Value: string; IsSecret: boolean },
    value: string | boolean
  ) => {
    const updatedHeaders = [...authProfile!.Headers!];
    updatedHeaders[index] = {
      ...updatedHeaders[index],
      [field]: value,
    };
    if (!authProfile) return;
    setAuthProfile({
      ...authProfile,
      Headers: updatedHeaders,
    });
  };

  const addHeader = () => {
    if (!authProfile) return;
    setAuthProfile({
      ...authProfile,
      Headers: [...(authProfile.Headers ?? []), { Key: '', Value: '', IsSecret: false }],
    });
  };

  const removeHeader = (index: number) => {
    if (
      authProfile &&
      (authProfile.Type === AuthenticationType.AuthenticationHeaders ||
        authProfile.Type === AuthenticationType.OAuthApplication)
    ) {
      const updatedHeaders = [...authProfile.Headers!];
      updatedHeaders.splice(index, 1);
      setAuthProfile({
        ...authProfile,
        Headers: updatedHeaders,
      });
    }
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack.Item>
        <Text variant='large'>Headers</Text>
        {authProfile?.Headers?.map((header, index) => (
          <Stack
            key={index}
            horizontal
            tokens={{ childrenGap: 10 }}
            className={styles.propContainer}
          >
            <TextField
              label='Key'
              value={header.Key}
              onChange={(e, value) => updateHeaderParameters(index, 'Key', value || '')}
            />
            <TextField
              label='Value'
              type={header.IsSecret ? 'password' : 'text'}
              value={header.Value}
              onChange={(e, value) => updateHeaderParameters(index, 'Value', value || '')}
            />
            <Checkbox
              className={styles.isSecret}
              id={`isSecretId-${index}`}
              checked={header.IsSecret}
              onRenderLabel={() => <span style={{ display: 'block' }}>Is Secret</span>}
              onChange={(e, checked) => updateHeaderParameters(index, 'IsSecret', checked || false)}
            />
            <CommandBarButton
              className={styles.removeButton}
              iconProps={{ iconName: 'Delete' }}
              onClick={() => removeHeader(index)}
              text='Remove'
            />
          </Stack>
        ))}
      </Stack.Item>
      <Stack.Item>
        <CommandBarButton iconProps={addIcon} text='Add Header' onClick={addHeader} />
      </Stack.Item>
    </Stack>
  );
};

export default FunctionFormHeaders;
