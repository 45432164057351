export interface IAgentAppBaseFunction {
  Id: number;
  InternalName: string;
  DisplayName: string;
  Description: string;
  ActionUrl: string;
  Created: string;
  Updated: string;
  FunctionType: string;
}

export interface IAgentAppFunction extends IAgentAppBaseFunction {
  ReturnValue: string;
  HttpMethod: IAgentFunctionHttpMethod;
  Parameters: AgentAppFunctionParameters;
  AuthProfile: AgentAppFunctionAuth;
}

export interface IAgentAppSearchFunction extends IAgentAppBaseFunction {
  TopResults: number;
  InputParameters: string;
  OutputParameters: string;
}

export interface IAgentAppSearchFunctionResults {
  description: string;
  title: string;
  url: string;
}

export interface AgentAppFunctionParameters {
  Type: string;
  Properties: Map<string, AgentAppFunctionProperty>;
  Required: string[];
}

export interface AgentAppFunctionProperty {
  Type: IAgentPropertyType;
  Description: string;
}

export type IAgentFunctionHttpMethod = 'Get' | 'Post' | 'Put' | 'Delete';

export type IAgentPropertyType = 'string' | 'integer' | 'double' | 'boolean';

export interface AgentAppFunctionAuth {
  Type: AuthenticationType;
  OAuthParameters?: OAuthParameters;
  Headers?: HeaderParameter[];
}

export enum AuthenticationType {
  PowerAutomate = 'PowerAutomate',
  OAuthApplication = 'OAuthClientCredentials',
  AuthenticationHeaders = 'AuthenticationHeaders',
}

// OAuth Authentication Parameters Interface
export interface OAuthParameters {
  ClientId: string;
  ClientSecret: string;
  TokenEndpoint: string;
  Scope: string;
}

export interface HeaderParameter {
  Key: string;
  Value: string;
  IsSecret: boolean;
}

export interface ITestAuthResponse {
  Success: boolean;
  ErrorMessage: string;
}
