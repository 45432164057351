import { FC, createContext, useContext } from 'react';
import { ApiHttpServiceContext } from './ApiHttpService';
import { CacheServiceContext } from './CacheService';
import IAgentApp from '../../Models/API/IAgentApp';
import IAgentAppPermission from '../../Models/API/IAgentAppPermission';
import {
  AgentAppFunctionAuth,
  AgentAppFunctionParameters,
  AgentAppFunctionProperty,
  IAgentAppBaseFunction,
  IAgentAppFunction,
  IAgentAppSearchFunction,
  IAgentAppSearchFunctionResults,
  IAgentFunctionHttpMethod,
  ITestAuthResponse,
} from '../../Models/API/IAgentAppFunction';
import IUserPermission, { PermissionType } from '../../Models/API/IUserPermission';

export interface INewAgentApp {
  DisplayName?: string;
  Description?: string;
  SystemPrompt?: string;
  AppCatalogId?: number;
}

export interface INewAgentAppFunction {
  DisplayName?: string;
  Description?: string;
  SystemPrompt?: string;
  ReturnValue?: string;
  HttpMethod?: IAgentFunctionHttpMethod;
  ActionUrl?: string;
  Parameters?: AgentAppFunctionParameters;
  AuthProfile?: AgentAppFunctionAuth;
  AppCatalogId?: number;
  FunctionType: string;
}

export interface INewAgentAppSearchFunction {
  DisplayName?: string;
  Description?: string;
  SystemPrompt?: string;
  ActionUrl?: string;
  AppCatalogId?: number;
  InputParameters: string;
  OutputParameters: string;
  FunctionType: string;
  TopResults: number;
}

export interface ITestAuthentication {
  HttpMethod?: IAgentFunctionHttpMethod;
  ActionUrl?: string;
  AuthProfile?: AgentAppFunctionAuth;
}

export interface IAgentAppService {
  // CRUD
  GetAll(): Promise<IAgentApp[] | null>;
  Get(agentAppId: number): Promise<IAgentApp | null>;
  Create(agentApp: INewAgentApp): Promise<IAgentApp | null>;
  Update(agentAppId: number, agentApp: INewAgentApp): Promise<IAgentApp | null>;
  Delete(agentAppId: number): Promise<void | null>;
  PublishAgentApp(agentAppId: number, publish: boolean): Promise<void>;

  // Functions
  GetAllAgentFunctions(agentAppId: number): Promise<IAgentAppBaseFunction[] | null>;
  GetAgentFunctions(agentAppId: number): Promise<IAgentAppFunction[] | null>;
  CreateFunction(
    agentAppId: number,
    agentFunction: INewAgentAppFunction
  ): Promise<IAgentAppFunction | null>;
  UpdateFunction(
    agentAppId: number,
    agentFunction: IAgentAppFunction
  ): Promise<IAgentAppFunction | null>;
  DeleteFunction(agentAppId: number, agentAppFunctionId: number): Promise<void | null>;
  TestAuthentication(testAuth: ITestAuthentication): Promise<ITestAuthResponse | null>;

  CreateSearchFunction(
    agentAppId: number,
    agentFunction: INewAgentAppSearchFunction
  ): Promise<IAgentAppSearchFunction | null>;
  UpdateSearchFunction(
    agentAppId: number,
    agentFunction: IAgentAppSearchFunction
  ): Promise<IAgentAppSearchFunction | null>;
  DeleteSearchFunction(agentAppId: number, agentAppSearchFunctionId: number): Promise<void | null>;
  ExecuteSearchFunction(
    agentAppId: number,
    agentAppSearchFunctionId: number,
    searchQuery: string,
    userId: string
  ): Promise<IAgentAppSearchFunctionResults[] | null>;

  // Permissions
  OverrideCatalogPermissions(
    agentAppId: number,
    overrideCatalogPermissions: boolean
  ): Promise<void>;
  CreatePermission(
    agentAppId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IAgentAppPermission | null>;
  GetPermissions(agentAppId: number): Promise<IAgentAppPermission[] | null>;
  UpdatePermission(
    catalogId: number,
    principalId: string,
    type: PermissionType
  ): Promise<IAgentAppPermission | null>;
  CheckUserPermissions(principalId: string, agentAppId: number): Promise<IUserPermission[] | null>;
  DeletePermission(catalogId: number, principalId: string): Promise<void | null>;
}

export const agentAppServiceContext = createContext<IAgentAppService | undefined>(undefined);

const AgentAppService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const controller: string = 'agentApps';

  const agentAppService: IAgentAppService = {
    async GetAll(): Promise<IAgentApp[] | null> {
      return await apiHttpService!.Get<IAgentApp[]>(`${controller}`);
    },
    async Get(agentAppId: number) {
      return await apiHttpService!.Get<IAgentApp>(`${controller}/${agentAppId}`);
    },
    async Delete(agentAppId: number) {
      return await apiHttpService!.Delete<void>(`${controller}/${agentAppId}`);
    },
    async Create(agentApp: INewAgentApp) {
      return await apiHttpService!.Post<IAgentApp>(`${controller}`, agentApp);
    },
    async Update(agentAppId: number, agentApp: INewAgentApp) {
      cacheService!.ClearCache(`${controller}/${agentApp}`);
      return await apiHttpService!.Put<IAgentApp>(`${controller}/${agentAppId}`, agentApp);
    },
    async PublishAgentApp(agentAppId: number, publish: boolean) {
      await apiHttpService!.Post<void>(`${controller}/${agentAppId}/publish?publish=${publish}`);
    },
    async GetAllAgentFunctions(agentAppId: number) {
      const fun = await apiHttpService!.Get<IAgentAppBaseFunction[]>(
        `${controller}/${agentAppId}/functions/all`
      );

      if (fun) {
        return fun?.map((x): IAgentAppBaseFunction => {
          if (x.FunctionType === 'SearchFunction') {
            return {
              Id: x.Id,
              InternalName: x.InternalName,
              DisplayName: x.DisplayName,
              Description: x.Description,
              ActionUrl: x.ActionUrl,
              Created: x.Created,
              Updated: x.Updated,
              InputParameters: (x as IAgentAppSearchFunction).InputParameters,
              OutputParameters: (x as IAgentAppSearchFunction).OutputParameters,
              FunctionType: x.FunctionType,
              TopResults: (x as IAgentAppSearchFunction).TopResults,
            } as IAgentAppSearchFunction;
          } else {
            return {
              Id: x.Id,
              InternalName: x.InternalName,
              DisplayName: x.DisplayName,
              Description: x.Description,
              ReturnValue: (x as IAgentAppFunction).ReturnValue,
              HttpMethod: (x as IAgentAppFunction).HttpMethod,
              ActionUrl: x.ActionUrl,
              Parameters: {
                Type: (x as IAgentAppFunction).Parameters!.Type,
                Properties: new Map<string, AgentAppFunctionProperty>(
                  Object.entries((x as IAgentAppFunction).Parameters!.Properties)
                ),
                Required: (x as IAgentAppFunction).Parameters!.Required,
              },
              AuthProfile: (x as IAgentAppFunction).AuthProfile,
              Created: x.Created,
              Updated: x.Updated,
              FunctionType: x.FunctionType,
            } as IAgentAppFunction;
          }
        });
      }

      return null;
    },
    async GetAgentFunctions(agentAppId: number) {
      const fun = await apiHttpService!.Get<IAgentAppFunction[]>(
        `${controller}/${agentAppId}/functions`
      );

      if (fun) {
        return fun?.map((x): IAgentAppFunction => {
          return {
            Id: x.Id,
            InternalName: x.InternalName,
            DisplayName: x.DisplayName,
            Description: x.Description,
            ReturnValue: x.ReturnValue,
            HttpMethod: x.HttpMethod,
            ActionUrl: x.ActionUrl,
            Parameters: {
              Type: x.Parameters.Type,
              Properties: new Map<string, AgentAppFunctionProperty>(
                Object.entries(x.Parameters.Properties)
              ),
              Required: x.Parameters.Required,
            },
            AuthProfile: x.AuthProfile,
            Created: x.Created,
            Updated: x.Updated,
            FunctionType: 'Function',
          };
        });
      }

      return null;
    },
    async CreateFunction(agentAppId: number, agentFunction: INewAgentAppFunction) {
      let propertiesToSave: any = {};

      if (agentFunction!.Parameters) {
        for (const [key, value] of agentFunction!.Parameters.Properties) {
          propertiesToSave[key] = value;
        }
      }

      const fun = {
        ...agentFunction,
        Parameters: {
          ...agentFunction.Parameters,
          Properties: propertiesToSave,
        },
      };

      return await apiHttpService!.Post<IAgentAppFunction>(
        `${controller}/${agentAppId}/functions`,
        fun
      );
    },
    async UpdateFunction(agentAppId: number, agentFunction: IAgentAppFunction) {
      let propertiesToSave: any = {};

      if (agentFunction!.Parameters) {
        for (const [key, value] of agentFunction!.Parameters.Properties) {
          propertiesToSave[key] = value;
        }
      }

      const fun = {
        ...agentFunction,
        Parameters: {
          ...agentFunction.Parameters,
          Properties: propertiesToSave,
        },
      };

      return await apiHttpService!.Put<IAgentAppFunction>(
        `${controller}/${agentAppId}/functions/${agentFunction.Id}`,
        fun
      );
    },
    async DeleteFunction(agentAppId: number, agentAppFunctionId: number) {
      return await apiHttpService!.Delete<void>(
        `${controller}/${agentAppId}/functions/${agentAppFunctionId}`
      );
    },
    async TestAuthentication(testAuth: ITestAuthentication) {
      return await apiHttpService!.Post<ITestAuthResponse>(`${controller}/testauth`, testAuth);
    },
    async CreateSearchFunction(agentAppId: number, agentFunction: INewAgentAppSearchFunction) {
      const fun = {
        ...agentFunction,
      };

      return await apiHttpService!.Post<IAgentAppSearchFunction>(
        `${controller}/${agentAppId}/searchfunctions`,
        fun
      );
    },
    async UpdateSearchFunction(agentAppId: number, agentFunction: IAgentAppSearchFunction) {
      const fun = {
        ...agentFunction,
      };

      return await apiHttpService!.Put<IAgentAppSearchFunction>(
        `${controller}/${agentAppId}/searchfunctions/${agentFunction.Id}`,
        fun
      );
    },
    async DeleteSearchFunction(agentAppId: number, agentAppFunctionId: number) {
      return await apiHttpService!.Delete<void>(
        `${controller}/${agentAppId}/searchfunctions/${agentAppFunctionId}`
      );
    },
    async ExecuteSearchFunction(
      agentAppId: number,
      agentAppSearchFunctionId: number,
      searchQuery: string,
      userId: string
    ) {
      return await apiHttpService!
        .Post(`${controller}/${agentAppId}/searchfunctions/${agentAppSearchFunctionId}/execute`, {
          SearchQuery: searchQuery,
          UserId: userId,
        })
        .then(x => {
          if (!x) return null;
          return (x as any).SearchFunctionResult as IAgentAppSearchFunctionResults[];
        });
    },
    async OverrideCatalogPermissions(agentAppId: number, overrideCatalogPermissions: boolean) {
      await apiHttpService!.Post<void>(
        `${controller}/${agentAppId}/overrideCatalogPermissions?overrideCatalogPermissions=${overrideCatalogPermissions}`
      );
    },
    async CreatePermission(agentAppId: number, principalId: string, type: PermissionType) {
      return await apiHttpService!.Post<IAgentAppPermission>(
        `${controller}/${agentAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async GetPermissions(agentAppId: number) {
      return await apiHttpService!.Get<IAgentAppPermission[]>(
        `${controller}/${agentAppId}/permissions`
      );
    },
    async DeletePermission(agentAppId: number, principalId: string) {
      await apiHttpService!.Delete<IAgentAppPermission>(
        `${controller}/${agentAppId}/permissions/${principalId}`
      );
    },
    async CheckUserPermissions(principalId: string, agentAppId: number) {
      return await apiHttpService!.Get<IUserPermission[]>(
        `${controller}/${agentAppId}/permissions/${principalId}`
      );
    },
    async UpdatePermission(agentAppId: number, principalId: string, type: PermissionType) {
      return await apiHttpService!.Put<IAgentAppPermission>(
        `${controller}/${agentAppId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
  };

  return (
    <agentAppServiceContext.Provider value={agentAppService}>
      {children}
    </agentAppServiceContext.Provider>
  );
};

export default AgentAppService;
